import React, { useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './Chats.css';  // Ensure your stylesheet is updated accordingly
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {CopyToClipboard} from 'react-copy-to-clipboard';

function Chats() {
  const [userQuery, setUserQuery] = useState('');
  const [fullConversation, setFullConversation] = useState([]);
  const [typing, setTyping] = useState(false);
  const [displayedConversation, setDisplayedConversation] = useState([]);
  const [savedConversations, setSavedConversations] = useState([]);
  const [generatedTitle, setGeneratedTitle] = useState(''); // State for the generated title
  const conversationRef = useRef(null);
  const userInputRef = useRef(null);
  const [activeConversationId, setActiveConversationId] = useState(null);
  
  

  const handleInputChange = () => {
	setUserQuery(userInputRef.current.textContent);
  };

  const handleGptInteraction = async () => {
	let typingInterval;
	try {
	  // Create the conversation context for GPT-4
	  const context = [
		...fullConversation,
		{ role: 'user', content: userQuery },
	  ];

	  // Add user query to conversation before making the API call
	  setFullConversation((prev) => [...prev, { role: 'user', content: userQuery }]);
	  setDisplayedConversation((prev) => [...prev, { role: 'user', content: userQuery }]);
	  userInputRef.current.textContent = '';
	  setTyping(true);

	  const response = await fetch('https://api.openai.com/v1/chat/completions', {
		method: 'POST',
		headers: {
		  'Content-Type': 'application/json',
		  Authorization: `Bearer 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`, // **Important:** Replace with a secure method to store your API key
		},
		body: JSON.stringify({
		  model: 'o1-preview', // Use the appropriate model
		  messages: context,
		}),
	  });

	  clearInterval(typingInterval);

	  const data = await response.json();

	  if (data.error) {
		throw new Error(data.error.message);
	  }

	  const assistantMessage = data.choices[0].message.content;

	  // Add the assistant's message directly to the conversation
	  setDisplayedConversation((prev) => [
		...prev,
		{ role: 'assistant', content: assistantMessage },
	  ]);

	  setFullConversation((prev) => [
		...prev,
		{ role: 'assistant', content: assistantMessage },
	  ]);

	  setTyping(false);
	} catch (error) {
	  clearInterval(typingInterval);
	  console.error('Failed to interact with GPT-4:', error);
	  setTyping(false);
	  setDisplayedConversation((prev) => [
		...prev,
		{ role: 'assistant', content: 'Sorry, something went wrong. Please try again later.' },
	  ]);
	}
  };

  const handleKeyPress = (event) => {
	if (event.charCode === 13 && !event.shiftKey) {
	  event.preventDefault();
	  handleGptInteraction();
	}
  };

  const handleClearChat = () => {
	setFullConversation([]);
	setDisplayedConversation([]);
	setGeneratedTitle(''); // Clear the generated title
  };

  const handleSaveConversation = async () => {
	const token = localStorage.getItem('authToken');  
	try {
	  // Generate a title for the saved conversation
	  const titleResponse = await fetch(`${process.env.REACT_APP_API_URL}/generate-title`, {
		method: 'POST',
		headers: {
		  'Authorization': `Bearer ${token}`,
		  'Content-Type': 'application/json'
		},
		body: JSON.stringify({ logs: fullConversation }),
	  });
	  const titleData = await titleResponse.json();
	  
	  // Save the conversation
	  const response = await fetch(`${process.env.REACT_APP_API_URL}/save-conversation`, {
		method: 'POST',
		headers: {
		  'Authorization': `Bearer ${token}`,	
		  'Content-Type': 'application/json'
		},
		body: JSON.stringify({ title: titleData.generatedTitle, messages: fullConversation }),
	  });
	  const data = await response.json();
	  console.log('Conversation saved:', data);
	  if (titleData.generatedTitle) {
		setGeneratedTitle(titleData.generatedTitle);
		// Optionally, you can update the conversation with the generated title
		const updatedConversation = { ...data, title: titleData.generatedTitle };
		setSavedConversations((prev) => [...prev, updatedConversation]);
	  } else {
		setSavedConversations((prev) => [...prev, data]);
	  }
	} catch (error) {
	  console.error('Error saving conversation:', error);
	}
  };

  const fetchSavedConversations = async () => {
	const token = localStorage.getItem('authToken');  
	try {
	  const response = await fetch(`${process.env.REACT_APP_API_URL}/saved-conversations`, {
		method: 'GET', // Changed to GET as it fetches data
		headers: {
		  'Authorization': `Bearer ${token}`,	
		  'Content-Type': 'application/json'
		}
	  });
	  const data = await response.json();
	  setSavedConversations(data);
	} catch (error) {
	  console.error('Error fetching saved conversations:', error);
	}
  };

  const handleLoadConversation = async (conversation) => {
	setFullConversation(conversation.messages);
	setDisplayedConversation(conversation.messages);
	setGeneratedTitle(conversation.title || '');
	setActiveConversationId(conversation.id); // Set active conversation ID
	if (userInputRef.current) {
	  userInputRef.current.textContent = '';
	}
  };

  useEffect(() => {
	if (conversationRef.current) {
	  conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
	}
  }, [displayedConversation]);

  useEffect(() => {
	fetchSavedConversations();
  }, []);

  return (
	<div className="analyze-modal-overlay">
	  <div className="analyze-modal-content">
		<div className="modal-split">
		  <div className="side-panel">
			<h1>Saved Conversations</h1>
			<ul>
			  {savedConversations.map((conversation, index) => (
				<li
				  key={index}
				  onClick={() => handleLoadConversation(conversation)}
				  className={activeConversationId === conversation.id ? 'active' : ''}
				>
				  {conversation.title.replace(/"/g, '') || `Conversation ${index + 1}`}
				</li>
			  ))}
			</ul>
		  </div>
		  <div className="chat-analysis-container">
			{/* Header with Buttons and Generated Title */}
			<div className="chat-header">
			  <div className="button-container">
				<button className="new-button" onClick={handleClearChat} title="Clear Chat">
				  <i className="fa fa-eraser" aria-hidden="true"></i>
				</button>
				<button className="new-button" onClick={handleSaveConversation} title="Save Conversation">
				  <i className="fa fa-cloud" aria-hidden="true"></i>
				</button>
			  </div>
			  {generatedTitle && (
				<div className="generated-title" title="Conversation Title">
				  <h1>{generatedTitle.replace(/"/g, '') }</h1>
				</div>
			  )}
			</div>
			{/* Scrollable Conversation Area */}
			<div className="ongoing-conversation" ref={conversationRef}>
			  {displayedConversation.map((message, idx) => (
				<div key={idx} className={`message ${message.role}`}>
				  {message.role === 'assistant' && message.content.includes('```') ? (
					message.content.split('```').map((part, index) => {
					  if (index % 2 === 0) {
						// Normal text (outside of ```
						return (
						  <ReactMarkdown key={index} remarkPlugins={[remarkGfm]}>
							{part}
						  </ReactMarkdown>
						);
					  } else {
						// Code part (inside of ```
						const lines = part.split('\n');
						const lang = lines[0].trim(); // Language identifier
						const code = lines.slice(1).join('\n').trim();
						return (
						  <CopyToClipboard
							key={index}
							text={code}
							onCopy={() => {
							  const notification = document.getElementById('notification');
							  if (notification) {
								notification.style.display = 'block';
								setTimeout(() => {
								  notification.style.display = 'none';
								}, 500);
							  }
							}}
						  >
						  <div className="syntax-highlighter">
							<SyntaxHighlighter language={lang} style={dracula}>
							  {code}
							</SyntaxHighlighter>
						  </div>
						  </CopyToClipboard>
						);
					  }
					})
				  ) : (
					<ReactMarkdown remarkPlugins={[remarkGfm]}>
					  {message.content}
					</ReactMarkdown>
				  )}
				</div>
			  ))}
			 {
			   typing && 
				 <div className="message assistant thinking">
				   Thinking <span>🧠</span><span>🧠</span><span>🧠</span>
				 </div>
			 }
			</div>
			{/* Input Area */}
			<div className="input-wrapper">
			  <div
				contentEditable="true"
				role="textbox"
				aria-multiline="true"
				data-placeholder="Send a message"
				ref={userInputRef}
				onInput={handleInputChange}
				onKeyPress={(e) => handleKeyPress(e)}
				className="editable-div"
			  ></div>
			  <button
				className={`send-button ${userQuery ? 'active' : ''}`}
				onClick={handleGptInteraction}
				title="Send Message"
			  >
				<i className="fas fa-paper-plane"></i>
			  </button>
			</div>
		  </div>
		</div>
	  </div>
	  <div id="notification" className="notification">
		  Copied to Clipboard!
	  </div>
	</div>
  );
}

export default Chats;
