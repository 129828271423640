import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useUser } from '../../UserContext';
import { useNavigate } from 'react-router-dom'; 
import styles from './Api.module.css';


function Api() {
  const { logoutUser } = useUser(); 
  const [apiKeys, setApiKeys] = useState([]);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false); // State for modal visibility
  const [verificationCode, setVerificationCode] = useState('');
  const [apiKeyExpirationDate, setApiKeyExpirationDate] = useState('');
  const verificationCodeRef = useRef(null);  // Create a ref	
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const openModal = () => {
	setModalIsOpen(true);
  };

  const closeModal = () => {
	setModalIsOpen(false);
	setVerificationCode('');
	setApiKeyExpirationDate('');
  };
  
  function VerificationModal({
	  
	verificationCodeRef,
	isFetching,
	isOpen,
	verificationCode,
	onVerificationCodeChange,
	apiKeyExpirationDate,
	onApiKeyExpirationDateChange,
	onVerify,
	onClose
  }) {
	

	const modalClass = (isOpen || isFetching) ? 'modalShow' : 'modal';

  
	const handleVerify = async () => {
  	await onVerify();
	};
  	console.log(isFetching);
	return (
		<div className={styles[modalClass]}>
			<div className={styles['modal-content']}>
				{isFetching ? (
					<div className={styles['loading-container']}>
						<div className={styles['loading-indicator-container']}>
							<div className={styles['loading-indicator']}></div>
						</div>
					</div>
				) : (
					<>
						<h2>2FA Verification</h2>
						<p>An email has been sent to you.</p>
						<div className={styles['input-group']}>
							<input
								type="text"
								value={verificationCode}
								onChange={(e) => onVerificationCodeChange(e.target.value)}
								placeholder="Enter Verification Code"
								aria-label="Verification Code"
								ref={verificationCodeRef}  // add the ref here
							/>
						</div>
						<div className={styles['input-group']}>
							<label htmlFor="expiration-date">Set Expiration Date:</label>
							<input
								id="expiration-date"
								type="date"
								value={apiKeyExpirationDate}
								onChange={(e) => onApiKeyExpirationDateChange(e.target.value)}
								placeholder="Select Expiration Date"
								aria-label="API Key Expiration Date"
							/>
						</div>
						<div className={styles['button-group']}>
							<button type="button" onClick={handleVerify} disabled={isFetching}>Verify Code</button>  
							<button type="button" onClick={onClose} disabled={isFetching}>Cancel</button>  
						</div>
					</>
				)}
			</div>
		</div>
	);
  }


  function ApiKeysList({ apiKeys, onDeleteAPIKey }) {
	return (
		<table className={styles['api-keys-table']}>
			<thead>
				<tr>
					<th>API Key</th>
					<th>Expiration Date</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				{apiKeys.map((apiKey) => (
					<tr key={apiKey.id}>
						<td>{apiKey.api_key}</td>
						<td>{apiKey.expiration_date}</td>
						<td>
							<button type="button" onClick={() => onDeleteAPIKey(apiKey.id)} className={styles['button']}>Delete</button>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
  }


	 
  
  
  
  const handleSendCode = async () => {
	  
	const token = localStorage.getItem('authToken');
	setIsFetching(true);
	try {
	  setError(null);
	  const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, {}, {
		headers: {
		  'Authorization': `Bearer ${token}`
		}
	  });
	  if (response.data.message) {
		openModal(); // Open the 2FA verification modal
	  } else {
		setError('Failed to send verification code.');
	  }
	} catch (error) {
	  console.error('Error sending verification code:', error);
	  setError('An error occurred while sending the verification code.');
	}
	finally {
	  setIsFetching(false);
	}
  };

  const handleVerifyCode = async () => {
	const token = localStorage.getItem('authToken');
	try {
	  setError(null);
	  const response = await axios.post(
		`${process.env.REACT_APP_API_URL}/verify-code`,
		{
		  verificationCode, 
		  expirationDate: apiKeyExpirationDate 
		},
		{
		  headers: {
			'Authorization': `Bearer ${token}`
		  }
		}
	  );
	  if (response.data.apiKey) {
		fetchAPIKeys(); // Refresh API keys after successful verification
		closeModal();   // Close the 2FA verification modal
	  } else {
		setError('Verification code is incorrect.');
	  }
	} catch (error) {
	  console.error('Error verifying code:', error);
	  setError('An error occurred while verifying the code.');
	} 
  };

  const fetchAPIKeys = async () => {
	const token = localStorage.getItem('authToken');
	try {
	  setError(null);
	  const response = await axios.get(
		`${process.env.REACT_APP_API_URL}/get-api-keys`,
		{
		  headers: {
			'Authorization': `Bearer ${token}`,
			'Content-Type': 'application/json'
		  }
		}
	  );
	  console.log(response.data);  // Add this line to inspect the data
	  setApiKeys(response.data.apiKeys || []);
	} catch (error) {
	  console.error('Error fetching API keys:', error);
	  setError('An error occurred while fetching the API keys.');
	}
  };

  
  const handleLogout = async () => {
		const token = localStorage.getItem('authToken');
		
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				}
			});
			if (response.ok) {
				console.log("Logout successful");
			} else {
				console.warn("Logout failed or wasn't necessary");
			}
		} catch (error) {
			console.error("Error during logout:", error);
			alert("An error occurred during logout. You might be logged out, but please check.");
		} finally {
			// Call the context's logout function here
			logoutUser();
		}
	};
	
	
  const handleAlerts = () => {
	  navigate("/dashboard");  
  };
  const handleChats = () => {
	  navigate("/chats");  
  };
  const handleStats = () => {
	  navigate("/stats");  
  };
  const handleDeleteAPIKey = async (apiKeyId) => {
	try {
	  setError(null);
	  const token = localStorage.getItem('authToken');
	  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/delete-api-key/${apiKeyId}`, {
		headers: {
		  'Authorization': `Bearer ${token}`
		}
	  });
	  if (response.data.message) {
		fetchAPIKeys(); // Refresh API keys after successful deletion
	  } else {
		setError('Failed to delete the API key.');
	  }
	} catch (error) {
	  console.error('Error deleting API key:', error);
	  setError('An error occurred while deleting the API key.');
	}
  };
  

  useEffect(() => {
	fetchAPIKeys();
  }, []);
  
  useEffect(() => {
	if (verificationCodeRef.current) {
	  verificationCodeRef.current.focus();  // Focus on the input field after each render
	}
  });
  	
	const dropdownRef = useRef(null); // Create a ref for the dropdown
	
	// Toggle dropdown function
	const handleDropdownToggle = (e) => {
	  e.stopPropagation(); // Prevent the click from immediately triggering handleClickOutside
	  const dropdown = dropdownRef.current;
	  const shouldShow = dropdown.style.display === 'none' || dropdown.style.display === '';
	  dropdown.style.display = shouldShow ? 'block' : 'none';
	};
	
	// Click outside handler
	const handleClickOutside = (event) => {
	  const dropdown = dropdownRef.current;
	  if (dropdown && !dropdown.contains(event.target)) {
		dropdown.style.display = 'none';
	  }
	};
	
	// useEffect to set up and clean up the event listener
	useEffect(() => {
	  document.addEventListener('click', handleClickOutside, true);
	
	  return () => {
		document.removeEventListener('click', handleClickOutside, true);
	  };
	}, []);
	
	
	return (
		<div className={styles['dashboard-container']}>
			<div className="dashboard-header">
			   <div className="left-header">
				 <img src="logo.svg" alt="nokt Logo" className="logo" />
				 <h1 className="noktHeader">nokt | API</h1>
			   </div>
				   <div className="nav-links">
					   <div className="nav-link"onClick={handleAlerts}>Alerts</div>
					   <div className="nav-link" onClick={handleStats}>Stats</div>
					   <div className="nav-link"onClick={handleChats} >Chats</div>
					   <div className="workhub-wrapper">
						   <span className="workhub-icon"></span>
						   <div className="nav-link-special">
							   <a href="https://www.workhub.nokt.io" target="_blank" rel="noopener noreferrer">WorkHub</a>
						   </div>
					   </div>
					   <div className="dropdown-wrapper">
						   <span className="dropdown-icon" onClick={handleDropdownToggle}></span>
						   <div className="dropdown-menu" ref={dropdownRef}>
							   <div className="nav-link" >API</div>
							   <div className="nav-link" onClick={handleLogout}>Logout</div>
						   </div>
					   </div>
				   </div>
				 </div>
			<div className={styles['api-container']}>
				{error && <div className={styles['api-error']}>{error}</div>}
				<div>
					<h2>Your API Keys</h2>
					<ApiKeysList apiKeys={apiKeys} onDeleteAPIKey={handleDeleteAPIKey} />
				</div>
				<button type="button" className={styles['generate-api-key-button']} onClick={handleSendCode}>Generate API Key</button>
				<VerificationModal 
					isOpen={modalIsOpen} 
					verificationCode={verificationCode} 
					onVerificationCodeChange={setVerificationCode}
					apiKeyExpirationDate={apiKeyExpirationDate} 
					onApiKeyExpirationDateChange={setApiKeyExpirationDate}
					onVerify={handleVerifyCode}
					isFetching={isFetching}
					onClose={closeModal}
					verificationCodeRef={verificationCodeRef} 			 
				/>
			</div>
		</div>
	);
   }
   
   export default Api;
