import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { UserProvider, useUser } from './UserContext';
import Registration from './components/Registration/Registration';
// import Dashboard from './components/Dashboard/Dashboard';
// import Stats from './components/Stats/Stats';
import Chats from './components/Chats/Chats';
import Api from './components/Api/Api';


function RoutesBasedOnAuth() {
    const { isRegistered } = useUser();
    
    if (isRegistered) {
        return (
            <Routes>
                {/* <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/stats" element={<Stats />} /> */}
                <Route path="/chats" element={<Chats />} />
                <Route path="/api-keys" element={<Api />} />
                <Route path="*" element={<Navigate to="/dashboard" />} />
            </Routes>
        );
    }

    return (
        <Routes>
            <Route path="/" element={<Registration />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
}

function App() {
    return (
        <Router>
            <UserProvider>
                <Routes>
                    <Route path="*" element={<RoutesBasedOnAuth />} />
                </Routes>
            </UserProvider>
        </Router>
    );
}

export default App;
