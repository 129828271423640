import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
const UserContext = createContext();

export const UserProvider = ({ children }) => {
	const [isRegistered, setIsRegistered] = useState(false);
	const navigate = useNavigate();
	
	const loginUser = () => {
		setIsRegistered(true);
	};

	const logoutUser = useCallback((reason) => {  // Add the reason parameter here
		localStorage.removeItem('authToken');
		setIsRegistered(false);

		if (reason === 'inactivity') {
			navigate('/'); // Redirect to the registration page
		}
	}, [navigate]);

	// Handle auto logout after 10 minutes of inactivity
	useEffect(() => {
		let logoutTimer;

		const resetTimer = () => {
			clearTimeout(logoutTimer);

			logoutTimer = setTimeout(() => {
				logoutUser('inactivity');  // Call the logoutUser function with the 'inactivity' reason here
				console.log('You have been logged out due to inactivity');
			}, 60 * 60 * 1000); // 10 minutes
		};

		window.addEventListener('mousemove', resetTimer);
		window.addEventListener('keydown', resetTimer);

		resetTimer();  // Initialize timer on component mount

		return () => {
			// Cleanup
			clearTimeout(logoutTimer);
			window.removeEventListener('mousemove', resetTimer);
			window.removeEventListener('keydown', resetTimer);
		};
	}, [logoutUser]);

	// Logout when browser is closed
	useEffect(() => {
		const handleBeforeUnload = () => {
			logoutUser();
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [logoutUser]);

	return (
		<UserContext.Provider value={{ isRegistered, loginUser, logoutUser }}>
			{children}
		</UserContext.Provider>
	);
};

export const useUser = () => {
	return useContext(UserContext);
};
