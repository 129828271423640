import './Registration.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../UserContext';
import noktLogo from './noktlogo.svg';
 


function Registration() {
const { loginUser } = useUser();
const navigate = useNavigate();

const handleLogin = async (e) => {
	e.preventDefault();
	const email = e.target.elements.email.value;
	const password = e.target.elements.password.value;
	
	try {
		const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
			method: 'POST',
			headers: {	
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ username: email, password })
		});

		if (response.ok) {
			
			const responseData = await response.json();  // Extract the JSON data
			const token = responseData.token;  // Get the token from responseData
			localStorage.setItem('authToken', token);
			loginUser();
			navigate("/chats");
		} else {
			console.log("Login failed");
			alert("Invalid credentials!");
		}
	} catch (error) {
		console.error("Error during login:", error);
		alert("An error occurred during login.");
	}
};


	const handleForgotPassword = () => {
		window.location.href = "https://www.nokt.io";
	}

	return (
		<div className="Registration">
			<div className="container">
				<div className="leftBox">
					<img src={noktLogo} alt="nokt Logo" className="noktlogo" />
				</div>
				<div className="rightBox">
					<form id="registrationForm" onSubmit={handleLogin}>
						<div>
							<label>Email:</label>
							<input type="email" name="email" placeholder="Enter your email" required />
						</div>
						<div style={{ marginTop: '20px' }}>
							<label>Password:</label>
							<input type="password" name="password" placeholder="Enter your password" required />
						</div>
						<div style={{ marginTop: '30px' }}>
							<button type="submit">Login</button>
						</div>
						<div style={{ marginTop: '20px' }}>
							<button type="button" className="forgot-password-button" onClick={handleForgotPassword}>Forgot Password?</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default Registration;
